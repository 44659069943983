<template>
  <div>
    <b-navbar type="light" variant="light" style="margin-left: -15px">
      <b-navbar-nav class="ms-auto">
        <b-nav-item-dropdown end id="UserMenuContainer">
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <b-avatar
              variant="primary"
              class="bg-primary text-white mr-1"
              :text="NameInitials">
            </b-avatar>
            {{ UserName }}
          </template>
          <b-dropdown-item style="cursor: pointer" @click="goToDeveloperData()">
            Editar datos
          </b-dropdown-item>
          <b-dropdown-item style="cursor: pointer" @click="SignOut()">
            Cerrar sesión
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>


  </div>


</template>

<script>
import store from "../store/index";

export default {
  name: "Menu",
  computed: {
    UserName() {
      return store.getters.getUser.business_name;
    },
    NameInitials() {
      var name = this.UserName.split(" ");
      var result = "";
      var idx = 0;
      name.forEach((element) => {
        idx += 1;
        if (idx <= 2) {
          result += element[0];
        }
      });
      return result;
    },
  },
  methods: {
    goToDeveloperData() {
      this.$router.push({ name: "DeveloperData" });
    },
    SignOut() {
      store.dispatch("setUser", null);
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
