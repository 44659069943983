<template>
  <div class="login-body">
    <section id="login-wrap">

      <div class="minpre-brand-wrap">
        <div>
          <img style="width: 80%" src="../../assets/images/logo-familia-feliz.svg"/>

          <h1>Sistema de Gestion del Plan Nacional de Vivienda Familia Feliz</h1>
        </div>

        <p class="more-info-contact"><span>Para asistencia:</span> <a href="timinpre@presidencia.gob.do" target="_blank">timinpre@presidencia.gob.do</a></p>
      </div>

      <form class="login-form">
        <div class="login-icon-wrap">    
          <img src="../../assets/images/user-icon.svg"/>
          <h2>Iniciar sesión</h2>
        </div>

      
        <div class="form-inputs-wrap">
          <!-- Email input -->
          <input type="email" v-model="email" class="form-control user-email" placeholder="Correo electrónico"/>

          <!-- Password input -->
          <input type="password" v-model="password" class="form-control user-password" placeholder="Contraseña"/>

          <div class="d-flex justify-content-between align-items-center">
            <!-- Checkbox -->
            <div class="form-check mb-0">
              <input class="form-check-input me-2" type="checkbox" value="" id="form2Example3"/>

              <label class="form-check-label password-remember" for="form2Example3">Recordar contraseña </label>            
              
            </div>
          </div>

          <span class="errors-messages" v-if="showError"> Usuario / Contraseña inválido </span>

          <div class="text-center mt-4 pt-2">
            <button type="button" class="login-button" @click="Signin()"> Iniciar sesión </button>
            <p class="forgot-password"><span>¿Olvidó su contraseña?</span> <a href="#">Recuperar</a></p>
          </div>
        </div><!--form-inputs-wrap-->
      </form>
    </section>
  </div><!--login body-->
</template>


<script>
import authenticationApi from "../../services/Authentication";
import _securityToken from "@/core/securityToken";
import store from "../../store/index";
export default {
  name: "Login",
  data() {
    return {
      email: null,
      password: null,
      showError: false,
    };
  },
  mounted() {
    if (store.getters.getUser != null && store.getters.getUser != undefined) {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    async Signin() {
      let self = this;
      authenticationApi
        .Login({
          email: self.email,
          password: self.password,
        })
        .then(
          (data) => {
            if (data.data.success) {
              _securityToken.registerToken(data.data);
              store.dispatch("setUser", data.data.user);
              this.$router.push({ name: "Home" });
            }
          },
          () => {
            self.showError = true;
          }
        );
    },
  },
};
</script>


<style>
/*Left side*/

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 2px;
  background: #c0bcbc;
}

.login-body{
  background: url("../../assets/images/fondo-familia.jpg");
  background-size: cover;
  height: 100vh;
  position: relative;
}

#login-wrap{
  position: absolute;
  right: 100px;
  top: 100px;
  width: 935px;
  height: 525px;
  display: flex;
  overflow: hidden;
  border-radius: .8rem;
  border-bottom: 4px solid #8e9fb229;    
}

.minpre-brand-wrap{
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width:467px;
  padding:30px;
  background: #ffffffc7;
}

.login-form{
  width:467px;
  padding:30px;
  background: #fff;
}

h1{
  margin-top: 28px;
  font-size: 21px;  
  line-height: 32px;
  color:#262525;
}

.more-info-contact{
  margin-top:30px;
}

.more-info-contact span{
  color: #545454;
}

.more-info-contact a{
  text-decoration:none;
  color: #2d60bd;
}

.more-info-contact a:hover{
  text-decoration: underline;
  color:#083b9a;
}

/*Form*/
.login-icon-wrap{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    margin-bottom:10px;
}

.login-icon-wrap img{
  width:100px;
  padding: 15px;
  border-radius: 100%;
  background: #f7f7f7;
}

.login-icon-wrap h2{
  margin-top: 18px;
  font-size: 30px;
  font-weight: 100;
}

.form-inputs-wrap .form-control{
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-size: 25px!important;
    background-position-x: 12px!important;
    background-position-y: 12px!important;
    padding: 11px 11px 12px 52px !important;
    border: 2px solid rgb(239, 239, 239) !important;
    border-radius: 3px !important;
    font-weight: 500;
}

.form-inputs-wrap .user-email{
  background: url("../../assets/images/login-icon-envelope.svg") no-repeat;
}

.form-inputs-wrap .user-password{
  background: url("../../assets/images/login-icon-password.svg") no-repeat;
}

.form-inputs-wrap .password-remember{
  font-size: 14px;
}

.form-inputs-wrap .errors-messages{
    display: block;
    width: 100%;
    padding: 6px!important;
    margin-top: 8px;
    font-size: 14px;
    text-align: center;
    color: #fff!important;
    background: #dd1010;
}

.form-inputs-wrap .login-button{
    width: 100%;
    padding: 14px 15px;
    color: #fff;
    border-radius: 4px;
    border: none;
    background: #2b5364;
}

.form-inputs-wrap .login-button:hover{
  background:#275d73;
}

.form-inputs-wrap .forgot-password{
  margin-top:10px;
  font-size: 14px;
}

.form-inputs-wrap .forgot-password a{
  color:#454545;
  font-weight: bold;
  text-decoration: none;
}

.form-inputs-wrap a:hover{
  text-decoration: underline;
}



</style>