import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    role: "",
    user: null,
  },
  getters: {
    getUserRole(state) {
      return state.role;
    },
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    SET_USER_ROLE(state, role) {
      state.role = role;
    },
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    async setUserRole(state, role) {
      this.commit("SET_USER_ROLE", role);
    },
    async setUser(state, user) {
      this.commit("SET_USER", user);
    },
  },
});

export default store;
