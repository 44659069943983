import Vue from "vue";
import App from "./App.vue";
import VueMask from "v-mask";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
Vue.config.productionTip = false;
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import DataTable from "@andresouzaabreu/vue-data-table";
import "@andresouzaabreu/vue-data-table/dist/DataTable.css";
import router from "./router";
import store from "./store/index";
window.bus = new Vue();
Vue.use(BootstrapVue);
Vue.use(VueMask);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(store);
Vue.component("data-table", DataTable);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
