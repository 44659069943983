import axios from "axios";
import { BASE_URL } from "@/config";
let token = localStorage.getItem("ff_token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

export const authApi = axios.create({
  baseURL: BASE_URL,
  timeout: undefined,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const api = axios.create({
  baseURL: BASE_URL,
  timeout: undefined,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const setToken = (tokenValue) => {
  api.defaults.headers.common["Authorization"] = "Bearer " + tokenValue;
};
export const removeToken = (tokenValue) => {
  api.defaults.headers.common["Authorization"] = "Bearer " + tokenValue;
};
