const routes = [
  //MAIN
  {
    path: "/crud/cabinetabove",
    name: "CabinetAbove",
    component: () => import("../views/DataCruds/CabinetAbove/Main.vue"),
  },
  {
    path: "/crud/cabinetbelow",
    name: "CabinetAbove",
    component: () => import("../views/DataCruds/CabinetBelow/Main.vue"),
  },
  {
    path: "/crud/constructionsystem",
    name: "ConstructionSystem",
    component: () => import("../views/DataCruds/ConstructionSystem/Main.vue"),
  },
  {
    path: "/crud/doors",
    name: "Doors",
    component: () => import("../views/DataCruds/Doors/Main.vue"),
  },
  //ADD
  {
    path: "/crud/cabinetabove/add",
    name: "AddCabinetAbove",
    component: () => import("../views/DataCruds/CabinetAbove/Add.vue"),
  },
  {
    path: "/crud/cabinetbelow/add",
    name: "AddCabinetAbove",
    component: () => import("../views/DataCruds/CabinetBelow/Add.vue"),
  },
  {
    path: "/crud/constructionsystem/add",
    name: "AddConstructionSystem",
    component: () => import("../views/DataCruds/ConstructionSystem/Add.vue"),
  },
  {
    path: "/crud/doors/add",
    name: "AddDoors",
    component: () => import("../views/DataCruds/Doors/Add.vue"),
  },

  //Edit
  {
    path: "/crud/cabinetabove/edit/:id",
    name: "EditCabinetAbove",
    component: () => import("../views/DataCruds/CabinetAbove/Edit.vue"),
  },
  {
    path: "/crud/cabinetbelow/edit/:id",
    name: "EditCabinetAbove",
    component: () => import("../views/DataCruds/CabinetBelow/Edit.vue"),
  },
  {
    path: "/crud/constructionsystem/edit/:id",
    name: "EditConstructionSystem",
    component: () => import("../views/DataCruds/ConstructionSystem/Edit.vue"),
  },
  {
    path: "/crud/doors/edit/:id",
    name: "EditDoors",
    component: () => import("../views/DataCruds/Doors/Edit.vue"),
  },
];

export default routes;
