import { api } from "../core/api";
const getPlatformRequests = async () => {
  const response = await api.get(`/api/get-all-access-request`);
  return response.data;
};

const updateEstatusPlatformRequest = async (platformId, data) => {
  const response = await api.put(
    `/api/admin/platform/request/${platformId}/status`,
    data
  );
  return response.data;
};

const completeDeveloperData = async (data) => {
  const response = await api.post(`/api/create-user`, data);
  return response.data;
};

export {
  getPlatformRequests,
  updateEstatusPlatformRequest,
  completeDeveloperData,
};
