<template>
  <div id="app">
    <link
      href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
      rel="stylesheet"
      integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
      crossorigin="anonymous"
    />
    <div
      id="wrapper"
      style="height: 100vh"
      v-if="!hideMenu"
      :class="wrapperClass"
    >
      <div>
        <MenuToggleBtn></MenuToggleBtn>

        <Menu></Menu>

        <ContentOverlay></ContentOverlay>

        <up-navbar />
      </div>

      <router-view class="p-3" />
    </div>

    <div v-if="hideMenu">
      <router-view />
    </div>
  </div>
</template>
<script>
import MenuToggleBtn from "@/components/MenuToggleBtn.vue";
import Menu from "@/components/Menu.vue";
import ContentOverlay from "@/components/ContentOverlay.vue";
import store from "@/store/index";
import UpNavbar from "./components/UpNavbar.vue";
export const ViewsWithoutMenu = ["Login", "CompleteData"];
export default {
  components: {
    MenuToggleBtn,
    Menu,
    ContentOverlay,
    UpNavbar,
  },
  watch: {
    $route(to) {
      if (!ViewsWithoutMenu.includes(to.name)) {
        if (
          store.getters.getUser == null ||
          store.getters.getUser == undefined
        ) {
          this.$router.push({ name: "Login" });
        }
      }
    },
  },

  created() {
    if (window.bus != undefined) {
      window.bus.$on("menu/toggle", () => {
        window.setTimeout(() => {
          this.isOpenMobileMenu = !this.isOpenMobileMenu;
        }, 200);
      });

      window.bus.$on("menu/closeMobileMenu", () => {
        this.isOpenMobileMenu = false;
      });
    }
  },

  data() {
    return {
      isOpenMobileMenu: false,
    };
  },

  computed: {
    hideMenu() {
      return ViewsWithoutMenu.includes(this.$route.name);
    },
    wrapperClass() {
      return {
        toggled: this.isOpenMobileMenu === true,
      };
    },
  },
};
</script>
<style lang="scss">
@import "styles/layout.scss";
@import "styles/menu-toggle-btn.scss";
@import "styles/menu.scss";
@import "styles/content-overlay.scss";
@import "styles/media-queries.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.primary,
.btn-primary {
  background: #003876 !important;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* Stackoverflow preview fix, please ignore */
.navbar-nav {
  flex-direction: row;
}

.nav-link {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

/* Fixes dropdown menus placed on the right side */
.ml-auto .dropdown-menu {
  left: auto !important;
  right: 0px;
}
</style>
