import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Authentication/Login.vue";
import CompleteData from "../views/Authentication/CompleteData.vue";
import datacruds from "@/router/dataCruds.js";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/CompleteData/:token",
    name: "CompleteData",
    component: CompleteData,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home/Dashboard.vue"),
  },
  {
    path: "/Projects",
    name: "Projects",
    component: () => import("../views/Projects/Main.vue"),
  },
  {
    path: "/RequestInbox",
    name: "RequestInbox",
    component: () => import("../views/RequestInbox/Main.vue"),
  },
  {
    path: "/DeveloperData/:projectId?",
    name: "DeveloperData",
    component: () => import("../views/DeveloperData/Main.vue"),
  },
  {
    path: "/Escrow/:projectId?",
    name: "Escrow",
    component: () => import("../views/Escrow/Main.vue"),
  },
  {
    path: "/HousingByTypology/:projectId?",
    name: "HousingByTypology",
    component: () => import("../views/HousingByTypology/Main.vue"),
  },
  {
    path: "/ProjectCurrentStatus/:projectId?",
    name: "ProjectCurrentStatus",
    component: () => import("../views/ProjectCurrentStatus/Main.vue"),
  },
  {
    path: "/ProjectDocumentation/:projectId?",
    name: "ProjectDocumentation",
    component: () => import("../views/ProjectDocumentation/Main.vue"),
  },
  {
    path: "/ProjectInformation/:projectId?",
    name: "ProjectInformation",
    component: () => import("../views/ProjectInformation/Main.vue"),
  },
  {
    path: "/ProjectSchemes/:projectId?",
    name: "ProjectSchemes",
    component: () => import("../views/ProjectSchemes/Main.vue"),
  },
  {
    path: "/Prequalified/",
    name: "Prequalified",
    component: () => import("../views/Prequalified/Main.vue"),
  },
  {
    path: "/Prequalified/Details/",
    name: "Details",
    component: () => import("../views/Prequalified/Details.vue"),
  },
  {
    path: "/Cruds",
    name: "Cruds",
    component: () => import("../views/DataCruds/Main.vue"),
  },
  ...datacruds,
];

const router = new VueRouter({
  routes,
});

export default router;
