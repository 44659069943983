<template>
  <section class="">
    <div class="container-fluid">
      <div class="row d-flex">
        <div class="col-md-8 p-0 m-0 login-image"></div>
        <div class="col-md-4 p-4" style="padding: 20px">
          <form class="p-4">
            <div
              class="d-flex flex-row justify-content-center justify-content-lg-start"
            >
              <h1 class="mb-0" style="font-size: 32px; color: gray">
                SISTEMA DE GESTION DEL PLAN NACIONAL DE VIVIENDA FAMILIA FELIZ
              </h1>
            </div>

            <div
              style="border-size: 2"
              class="divider d-flex align-items-center my-4"
            ></div>

            <!-- Email input -->
            <div class="form-outline mb-4">
              <input
                type="text"
                v-model="name"
                class="form-control form-control-lg"
                placeholder="Ingrese su nombre"
              />
            </div>

            <!-- Password input -->
            <div class="form-outline mb-3">
              <input
                type="password"
                v-model="password"
                class="form-control form-control-lg"
                placeholder="Ingrese una contraseña"
              />
            </div>
            <div class="text-center mt-4 pt-2">
              <button
                type="button"
                class="btn btn-primary col-md-12"
                style="padding-left: 2.5rem; padding-right: 2.5rem"
                @click="CompleteData()"
              >
                Completar Información
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { completeDeveloperData } from "../../services/PlatformRequest";
import store from "../../store/index";
import Swal from "sweetalert2";
export default {
  name: "CompleteData",
  data() {
    return {
      name: null,
      password: null,
    };
  },
  computed: {
    token() {
      return this.$router.params.token;
    },
  },
  mounted() {
    if (store.getters.getUser != null && store.getters.getUser != undefined) {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    async CompleteData() {
      await completeDeveloperData({
        business_name: this.name,
        password: this.password,
        token: this.token,
      })
        .then((data) => {
          Swal.fire({
            title: "¡Proceso exitoso!",
            text: data,
            icon: "success",
            confirmButtonText: "Listo",
          });
          this.$router.push({ name: "Login" });
        })
        .catch(() => {
          Swal.fire({
            title: "¡Error al intentar enviar el formulario!",
            text: "Favor verifique si todos los campos han sido completados",
            icon: "error",
            confirmButtonText: "Volver",
          });
        });
    },
  },
};
</script>

<style>
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 2px;
  background: #c0bcbc;
}
.login-image {
  background: url("../../assets/images/fondo-familia.jpg");
  background-size: cover;
  height: 100vh;
}
</style>
